<template>
  <v-dialog v-model="dialog" max-width="750px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mt-1 ml-1 mr-1" icon v-bind="attrs" v-on="on"
        ><v-icon dark> mdi-minus </v-icon></v-btn
      >
    </template>
    <v-card flat class="pa-5">
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider>ユーザーの削除</v-card-title
      >
      <v-card-subtitle class="mt-1"
        >既存のユーザーを削除します。</v-card-subtitle
      >
      <v-card outlined class="ma-5">
        <v-row>
          <v-col>
            <v-data-table
              v-model="deleteUser"
              show-select
              single-select
              :headers="user_header"
              :items="user_list"
              item-key="person_in_charge_id"
              :items-per-page="10"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-alert
        class="mt-5 ml-5 mr-5"
        v-show="updateFlag.type != null"
        dense
        outlined
        :type="updateFlag.type"
        >{{ updateFlag.msg }}
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          x-large
          :dark="!inputFlag"
          :disabled="inputFlag"
          rounded
          class="cyan darken-3 pl-10 pr-10"
          @click="flag = !flag"
          >削除</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-dialog max-width="750px" v-model="flag">
        <v-card flat>
          <v-card-title
            ><v-divider vertical class="red darken-3 pa-1 ma-1"></v-divider>ユーザーの削除</v-card-title
          >
          <v-card-text
            >本当に削除しますか？この操作は元に戻せません。</v-card-text
          >

          <v-card-actions>
            <v-alert
              v-show="deleteFlag.type != null"
              dense
              outlined
              :type="deleteFlag.type"
              >{{ deleteFlag.msg }}
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn outlined @click="flag = !flag">キャンセル</v-btn>
            <v-btn
              class="pl-5 pr-5 cyan darken-3"
              dark
              elevation="0"
              :loading="userLoading"
              @click="submitNewUser()"
              >削除</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from "axios";
import Auth from "@aws-amplify/auth";

export default {
  name: "addUsers",
  props: {
    user_list: {
      type: Array,
    },
  },
  data: () => ({
    dialog: false,
    deleteUser: null,
    userLoading: false,
    flag: false,
    updateFlag: {
      type: null,
      msg: null,
    },
    deleteFlag: {
      type: null,
      msg: null,
    },
    user_header: [
      { text: "アカウント名", value: "person_in_charge" },
      { text: "ユーザー名", value: "display_name" },
      { text: "部署", value: "division" },
      { text: "email", value: "email" },
    ],
  }),
  computed: {
    inputFlag() {
      return (
        this.display_name === null ||
        this.division === null ||
        this.email === null
      );
    },
  },
  methods: {
    async submitNewUser() {
      if (this.deleteUser === null) {
        return;
      }

      this.userLoading = true;
      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/user/" +
          this.deleteUser[0].person_in_charge_id
      );

      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.deleteFlag.type = "error";
          this.deleteFlag.msg = "認証エラー";
        });

      await axios
        .delete(URI, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then(() => {
          this.deleteFlag.type = "success";
          this.deleteFlag.msg = "ユーザーを削除しました";
        })
        .catch((err) => {
          const errMsg = err.response.data.response_info;

          if (errMsg.includes("notAuthorizedUser")) {
            this.deleteFlag.type = "error";
            this.deleteFlag.msg = "ユーザー削除の権限がありません";
          }
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
  },
};
</script>
