<template>
  <v-container class="grey lighten-5 mt-10 pt-10">
    <v-card flat :max-width="$vuetify.breakpoint.width" class="grey lighten-5">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="text-center">
          <v-card flat class="pa-10 grey lighten-5">
            <v-img
              :src="require('../assets/starlingDB-logo.png')"
              contain
              color="grey lighten-5"
              height="250"
            />
            <v-card-text class="text-center text-h4 grey--text text--darken-1"
              >R-Buckets</v-card-text
            >
          </v-card>
        </v-col>
        <v-col> </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="text-center">
          <v-card flat outlined class="mt-5 pl-10 pr-10 pt-5 pb-5 mb-5">
            <v-card-title>ログイン</v-card-title>
            <v-form>
              <v-text-field
                v-model="username"
                label="アカウント名"
                required
                outlined
                single-line
                background-color="grey lighten-4"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="パスワード"
                required
                outlined
                single-line
                background-color="grey lighten-4"
                type="password"
              ></v-text-field>
              <v-text-field
                v-model="newPassword"
                v-if="firstLogin"
                label="新しいパスワード"
                hint="英数字8文字以上の新規パスワードを作成してください。"
                required
                outlined
                single-line
                background-color="grey lighten-4"
                type="password"
                :rules="[rules.password]"
              >
              </v-text-field>
              <v-btn
                class="text-h6"
                block
                depressed
                dark
                x-large
                color="cyan darken-3"
                :loading="loginLoading"
                @click="signIn()"
              >
                ログイン
              </v-btn>
              <v-card-text
                >初回ログインは<a @click="firstLogin = true">こちら</a> <br />
                <changePassword></changePassword
              ></v-card-text>

              <v-card class="pt-5" v-if="signInErrorMsg != ''" elevation="0">
                <v-alert dense outlined type="error">
                  {{ signInErrorMsg }}
                </v-alert>
              </v-card>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Auth from "@aws-amplify/auth";
import changePassword from "../components/changePassword";

export default {
  data() {
    return {
      loginLoading: false,
      username: "",
      password: "",
      newPassword: "",
      signInErrorMsg: "",
      firstLogin: false,
      rules: {
        password: (value) => {
          const pattern = /^([a-zA-Z0-9]{8,})$/;
          return (
            pattern.test(value) ||
            "英数字8文字以上の新規パスワードを作成してください。passwordの形式が不適切です。"
          );
        },
      },
    };
  },
  components: {
    changePassword,
  },
  created() {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          this.$store.dispatch("setSignedIn", true);
        }
      })
      .catch(() => {
        this.$store.dispatch("setSignedIn", false);
      });
  },
  methods: {
    async signIn() {
      this.loginLoading = true;
      await Auth.signIn(this.username, this.password)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(
              user, // the Cognito User Object
              this.newPassword // the new password
            )
              .then((user) => {
                this.$store.dispatch("setSignedIn", !!user);
                this.$store.dispatch("setUser", user.username);

                let companyName =
                  user.signInUserSession.idToken.payload["cognito:groups"][0];
                this.$store.dispatch(
                  "setCompanyName",
                  companyName.slice(0, companyName.length - 10)
                );
                this.$router.push("/");
              })
              .catch(() => {
                this.signInErrorMsg =
                  "IDまたはパスワードが違うか、アカウントがロックされています。";
              });
          } else {
            this.$store.dispatch("setSignedIn", !!user);
            this.$store.dispatch("setUser", user.username);

            let companyName =
              user.signInUserSession.idToken.payload["cognito:groups"][0];
            this.$store.dispatch(
              "setCompanyName",
              companyName.slice(0, companyName.length - 10)
            );
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.signInErrorMsg =
            "IDまたはパスワードが違うか、アカウントがロックされています。";
        })
        .finally(() => {
          this.loginLoading = false;
        });
    },
  },
};
</script>