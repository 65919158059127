<template>
  <v-card flat class="pa-5">
    <v-card outlined class="pa-5">
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >ファイル選択</v-card-title
      >
      <v-radio-group v-model="currentStatus" row class="ml-6" required>
        <v-radio
          v-for="n in currentStatusTitles"
          :key="n.val"
          :label="n.title"
          :value="n.val"
        ></v-radio>
      </v-radio-group>

      <v-select
        v-model="propertyType"
        :items="propertyTypeList"
        attach
        outlined
        label="物件種別"
        :rules="[rules.propertyType]"
      ></v-select>

      <v-file-input
        v-model="inputFile"
        background-color="white"
        outlined
        accept=".html,.htm"
        chips
        clearable
        multiple
        label="ファイル"
        hint="ファイルは同時に5つまで選択可能です。"
        persistent-hint
        required
        :rules="inputFileRule"
      ></v-file-input>
    </v-card>

    <v-card outlined class="mt-5 pa-5">
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >ファイル情報</v-card-title
      >
      <v-text-field
        v-model="title"
        label="タイトル"
        outlined
        background-color="white"
        required
        :rules="[rules.title]"
      ></v-text-field>

      <v-textarea
        filled
        label="メモ"
        counter
        outlined
        maxlength="400"
        persistent-hint
        v-model="memo"
      ></v-textarea>
    </v-card>

    <v-card outlined class="mt-5 pa-5">
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >抽出設定</v-card-title
      >
      <v-select
        v-model="filterType"
        :items="filterTypeList"
        attach
        outlined
        :menu-props="{ maxHeight: 200 }"
        label="抽出タイプ"
        require
      ></v-select>
      <v-card-text class="text-left"
        >{{ filterType }} : {{ filterTypeText }}</v-card-text
      >
    </v-card>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        x-large
        :dark="!inputFlag"
        :disabled="inputFlag"
        rounded
        class="cyan darken-3 pl-10 pr-10"
        :loading="fileUpLoading"
        @click="submitSendFile()"
        >登録</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-card-actions v-if="fileUrl != null">
      <v-spacer></v-spacer>
      <v-btn
        x-large
        dark
        rounded
        class="cyan darken-3 pl-10 pr-10"
        :href="fileUrl"
        >ダウンロード</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-alert
      class="mt-5"
      v-show="updateFlag.type != null"
      dense
      outlined
      :type="updateFlag.type"
      >{{ updateFlag.msg }}
    </v-alert>
  </v-card>
</template>

<script>
import axios from "axios";
import Auth from "@aws-amplify/auth";

export default {
  name: "inputHtmlFile",

  data: () => ({
    currentStatus: "seiyaku",
    currentStatusTitles: [
      { title: "在庫", val: "zaiko" },
      { title: "成約", val: "seiyaku" },
    ],
    propertyType: null,
    propertyTypeList: [
      "売マンション",
      "売土地",
      "売一戸建",
      "売外全(住宅以外建物全部)",
      "賃貸マンション",
      "賃貸外一(住宅以外建物一部)",
      "賃貸外全(住宅以外建物全部)",
    ],
    filterType: null,
    title: null,
    inputFile: [],
    fileUpLoading: false,
    memo: null,
    updateFlag: {
      type: null,
      msg: null,
    },
    fileUrl: null,
    inputFileRule: [(value) => value.length <= 5 || "file数は最大5つまでです。"],
    rules: {
      propertyType: (value) => {
        return !value === false || "物件種別は必須です。";
      },
      title: (value) => {
        return !value === false || "タイトルは必須です。";
      },
      account: (value) => {
        const pattern = /^([a-z0-9!@#$%^&*()_+-={}]{4,})$/;
        return (
          pattern.test(value) ||
          "アカウント名の形式が不適切です。英（小文字のみ）数字記号（!@#$%^&*()_+-={}）4文字以上で設定してください。"
        );
      },
    },
  }),
  computed: {
    inputFlag() {
      return !this.title || this.inputFile.length === 0;
    },
    filterTypeList() {
      let filterTypes = this.$store.state.filterType;
      let filterTypeList = ["設定なし"];

      if (filterTypes.length > 0) {
        filterTypeList = filterTypeList.concat(filterTypes);
      }
      return filterTypeList;
    },
    filterTypeText() {
      let filterObj = {};
      for (let [k, v] of Object.entries(this.$store.state.filterDetail)) {
        filterObj[k] = v.filter_type_detail;
      }

      let filterTypeText = {
        ...{ 設定なし: "filterなし" },
        ...filterObj,
      };

      return filterTypeText[this.filterType];
    },
  },
  methods: {
    getUrlFromFile(file) {
      return new Promise((resolve) => {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          resolve(fr.result);
        });
      });
    },
    async submitSendFile() {
      // init
      this.fileUrl = null;
      this.updateFlag = {
        type: null,
        msg: null,
      };

      if (
        this.currentStatus === null ||
        this.propertyType == null ||
        this.title === null ||
        this.inputFile.length === 0 ||
        this.inputFile.length > 5
      ) {
        return;
      }

      this.fileUpLoading = true;
      let urlList = [];
      let url;
      for (let file of this.inputFile) {
        url = await this.getUrlFromFile(file);
        urlList.push(url);
      }

      const selectedFiles = {
        title: this.title,
        memo: this.memo,
        add_cols: this.addCols,
        url: urlList,
      };

      // put
      let queryFilterType =
        "?currentstatus=" +
        this.currentStatus +
        "&propertytype=" +
        this.propertyType;

      if (this.filterType != "設定なし" && this.filterType != null) {
        queryFilterType = queryFilterType + "&filtertype=" + this.filterType;
      }

      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/file" +
          queryFilterType
      );

      // get token from cognito
      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      await axios
        .put(URI, JSON.stringify(selectedFiles), {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          const fileUrl = response.data.response_info;
          this.fileUrl = fileUrl;
        })
        .catch((err) => {
          let errMsg = err.response.data.response_info;
          if (errMsg.includes("invalidFiles")) {
            this.updateFlag.msg =
              "登録に失敗しました。htmlファイルの形式/内容が不正です。htmlファイルを再取得し、再度実行してください。";
          } else {
            this.updateFlag.msg = "登録に失敗しました。" + errMsg;
          }

          this.updateFlag.type = "error";
          this.fileUpLoading = false;
        })
        .finally(() => {
          this.fileUpLoading = false;
          return;
        });
    },
  },
};
</script>
