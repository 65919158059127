<template>
  <v-dialog
    max-width="$vuetify.breakpoint.width-100"
    @click:outside="backToLogin()"
    v-model="flag"
  >
    <template v-slot:activator="{ on, attrs }">
      <p>
        現行のパスワードを忘れた場合は <a v-bind="attrs" v-on="on">こちら</a>
      </p>
    </template>
    <v-card flat>
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >パスワードの再設定</v-card-title
      >
      <v-stepper v-model="e1" vertical>
        <v-stepper-step :complete="e1 > 1" step="1" color="cyan darken-3">
          リセット（検証）コードの送信
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card class="mt-5 ml-5 mr-5 pt-5 pl-10 pr-10" outlined>
            <v-card-subtitle
              >設定されたメールアドレスにリセット（検証）コードが送信されます。</v-card-subtitle
            >
            <v-row class="grey lighten-4 pl-10 pr-10 pt-5" no-gutters>
              <v-col cols="4">
                <v-subheader>アカウント名：</v-subheader>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="insertUsername"
                  outlined
                  background-color="white"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-btn
                class="mt-2 mb-2 pl-5 pr-5"
                color="cyan darken-3"
                dark
                elevation="0"
                :loading="sendingCode"
                @click="sendCodeConfirm()"
              >
                次へ
              </v-btn>
              <v-spacer></v-spacer>
              <v-alert
                class="mt-2"
                v-show="updateFlag1.type != null"
                dense
                outlined
                :type="updateFlag1.type"
                >{{ updateFlag1.msg }}
              </v-alert>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step :complete="e1 > 2" step="2" color="cyan darken-3">
          再設定
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card class="mt-5 ml-5 mr-5 pt-5 pl-10 pr-10" outlined>
            <v-card-subtitle class="">
              設定されたメールアドレスにリセット（検証）コードが送信されました。メール本文記載のコードを入力し、
              <strong class="red--text text--lighten-1">英数字8文字</strong>
              以上の新しいパスワードを作成してください。
            </v-card-subtitle>
            <v-row class="grey lighten-4 pl-10 pr-10 pt-5" no-gutters>
              <v-col cols="4">
                <v-subheader>リセット（検証）コード：</v-subheader>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="code"
                  outlined
                  background-color="white"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="grey lighten-4 pl-10 pr-10 pt-5" no-gutters>
              <v-col cols="4">
                <v-subheader>新しいパスワード：</v-subheader>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="newPassword"
                  outlined
                  background-color="white"
                  required
                  type="password"
                  :rules="[rules.password]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-btn
                class="mt-2 mb-2 pl-10 pr-10"
                color="cyan darken-3"
                dark
                elevation="0"
                :loading="this.changingPassword"
                @click="setNewPasswordWithCode()"
                >変更</v-btn
              >
              <v-btn @click="flag = !flag" outlined>キャンセル</v-btn>
              <v-spacer></v-spacer>
              <v-alert
                class="mt-2"
                v-show="updateFlag2.type != null"
                dense
                outlined
                :type="updateFlag2.type"
                >{{ updateFlag2.msg }}
              </v-alert>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import Auth from "@aws-amplify/auth";

export default {
  data() {
    return {
      e1: 1,
      flag: false,
      sendingCode: false,
      changingPassword: false,
      code: null,
      newPassword: null,
      insertUsername: null,
      updateFlag1: {
        type: null,
        msg: null,
      },
      updateFlag2: {
        type: null,
        msg: null,
      },
      rules: {
        password: (value) => {
          const pattern = /^([a-zA-Z0-9]{8,})$/;
          return (
            pattern.test(value) ||
            "passwordの形式が不適切です。"
          );
        },
      },
    };
  },
  methods: {
    async sendCodeConfirm() {
      this.sendingCode = true;

      await Auth.forgotPassword(this.insertUsername)
        .then(() => {
          this.e1 = 2;
        })
        .catch(() => {
          this.updateFlag1.type = "error";
          this.updateFlag1.msg = "リセットコード送信に失敗しました";
        })
        .finally(() => {
          this.sendingCode = false;
        });
    },
    async setNewPasswordWithCode() {
      this.changingPassword = true;
      await Auth.forgotPasswordSubmit(
        this.insertUsername,
        this.code,
        this.newPassword
      )
        .then(() => {
          this.updateFlag2.type = "success";
          this.updateFlag2.msg = "新しいパスワードに変更しました";
        })
        .catch(() => {
          this.updateFlag2.type = "error";
          this.updateFlag2.msg = "パスワードの変更に失敗しました";
        })
        .finally(() => {
          this.changingPassword = false;
        });
    },
    backToLogin() {
      this.sendingCode = false;
      this.code = null;
      this.changingPassword = false;
      this.currentPassword = null;
      this.newPassword = null;
      this.verifyPassword = null;
      this.insertUsername = null;
      this.updateFlag1 = {
        type: null,
        msg: null,
      };
      this.updateFlag2 = {
        type: null,
        msg: null,
      };
    },
  },
};
</script>
