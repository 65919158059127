<template>
  <v-card flat class="pa-5">
    <v-select
      v-model="value"
      :items="items"
      attach
      outlined
      label="検索方法"
      multiple
      chips
      @change="last_evaluated_key = null"
    ></v-select>

    <v-card outlined class="pt-5 pl-5 pr-5" v-if="value.includes('ユーザー名')">
      <v-row>
        <v-card-title
          ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
          >ユーザー名</v-card-title
        >
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="personInCharge"
            :items="userNameList"
            attach
            outlined
            label="ユーザー名"
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <v-card outlined class="mt-5 pt-5 pl-5 pr-5" v-if="value.includes('日時')">
      <v-row>
        <v-card-title
          ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
          >日時</v-card-title
        >
      </v-row>
      <v-row>
        <v-col cols="10" sm="10" md="6" lg="6" xl="6">
          <datepick
            label="日時下限"
            @selected-date="startDate = $event"
          ></datepick>
        </v-col>
        <v-col cols="10" sm="10" md="6" lg="6" xl="6">
          <datepick
            label="日時上限"
            @selected-date="endDate = $event"
          ></datepick
        ></v-col>
      </v-row>
    </v-card>
    <v-card
      outlined
      class="mt-5 pt-5 pl-5 pr-5"
      v-if="value.includes('タイトル')"
    >
      <v-row>
        <v-card-title
          ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
          >タイトル</v-card-title
        >
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="title"
            label="タイトル"
            outlined
            hint="前方一致検索です"
            background-color="white"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-card-actions class="mt-10">
      <v-spacer></v-spacer>
      <v-btn
        x-large
        :dark="!inputFlag"
        :disabled="inputFlag"
        rounded
        class="cyan darken-3 pl-10 pr-10"
        :loading="fileUpLoading"
        @click="getFileStart()"
        >取得</v-btn
      >

      <v-btn
        v-if="last_evaluated_key != null"
        x-large
        rounded
        dark
        class="cyan darken-3 pl-5 pr-5"
        @click="getFile()"
        >次のファイル情報を取得</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-alert
      class="mt-5"
      v-show="updateFlag.type != null"
      dense
      outlined
      :type="updateFlag.type"
      >{{ updateFlag.msg }}
    </v-alert>

    <v-card outlined class="mt-5">
      <v-data-table
        v-model="selected"
        :headers="header"
        :items="gotFiles"
        :items-per-page="10"
        class="elevation-0"
      >
        <template #item.type="{ item }">
          <a :href="item.link">{{ item.type }}</a>
        </template>
        <template #item.created_date="{ item }">
          {{ removeT(item.created_date) }}
        </template>
        <template #item.file_s3_path="{ item }">
          <a :href="item.signed_url">{{ item.file_s3_path }}</a>
        </template>

        <!-- delete button -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="
              deleteFilePath = item.file_s3_path;
              deleteTitle = item.title;
              flag = !flag;
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        max-width="750px"
        v-model="flag"
        @click:outside="initDeleteFlag()"
      >
        <v-card flat>
          <v-card-title
            ><v-divider vertical class="red darken-3 pa-1 ma-1"></v-divider
            >ファイルの削除</v-card-title
          >
          <v-card-text
            >{{ deleteTitle }}（ {{ deleteFilePath }}）
            を削除しますか？この操作は元に戻せません。</v-card-text
          >

          <v-card-actions>
            <v-alert
              v-show="deleteFlag.type != null"
              dense
              outlined
              :type="deleteFlag.type"
              >{{ deleteFlag.msg }}
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="
                flag = !flag;
                initDeleteFlag();
              "
              >キャンセル</v-btn
            >
            <v-btn
              class="pl-5 pr-5 cyan darken-3"
              dark
              elevation="0"
              :loading="deleteLoding"
              @click="deleteItem()"
              >削除</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-card>
</template>

<script>
import axios from "axios";
import Auth from "@aws-amplify/auth";
import datepick from "./datepick";

export default {
  name: "getHistoryFile",

  data: () => ({
    flag: false,
    inputFile: null,
    personInCharge: null,
    userListFlag: true,
    title: null,
    memo: null,
    value: [],
    items: ["ユーザー名", "日時", "タイトル"],
    startDate: null,
    endDate: null,
    updateFlag: {
      type: null,
      msg: null,
    },
    fileUpLoading: false,
    gotFiles: [],
    selected: [],
    header: [
      { text: "タイトル", value: "title" },
      { text: "ユーザー名", value: "display_name" },
      { text: "アップロード日時", value: "created_date" },
      { text: "file名", value: "file_s3_path" },
      { text: "備考", value: "memo" },
      { text: "削除", value: "actions", sortable: false },
    ],
    last_evaluated_key: null,
    deleteTitle: null,
    deleteFilePath: null,
    deleteFlag: {
      type: null,
      msg: null,
    },
    deleteLoding: false,
  }),
  components: { datepick },

  computed: {
    inputFlag() {
      return (
        this.title === null &&
        this.personInCharge === null &&
        (this.startDate === null || this.endDate === null)
      );
    },
    userNameList() {
      return this.$store.state.userList.map(function (element) {
        return element.display_name;
      });
    },
  },
  methods: {
    removeT(date) {
      return date.split("T")[0];
    },
    async getFileStart() {
      this.last_evaluated_key = null;
      await this.getFile();
    },
    async getFile() {
      this.fileUpLoading = true;
      this.updateFlag.type = null;
      this.updateFlag.msg = null;

      let targetUser = null;
      if (this.personInCharge != null) {
        targetUser = this.$store.state.userList.filter((row) => {
          return row.display_name === this.personInCharge;
        });
      }

      let query = {
        title: null,
        startDate: null,
        endDate: null,
        person_in_charge_id: null,
      };

      this.value.forEach((row) => {
        if (row == "タイトル") {
          query.title = !this.title ? null : this.title;
        } else if (row == "日時") {
          query.startDate = !this.startDate ? null : this.startDate;
          query.endDate = !this.endDate ? null : this.endDate;
        } else if (row == "ユーザー名") {
          query.person_in_charge_id =
            targetUser === null ? null : targetUser[0].person_in_charge_id;
        }
      });

      // ページネーション
      const body = this.last_evaluated_key;

      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/file" +
          "?title=" +
          query.title +
          "&start_date=" +
          query.startDate +
          "&end_date=" +
          query.endDate +
          "&person_in_charge_id=" +
          query.person_in_charge_id
      );

      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      await axios
        .post(URI, JSON.stringify(body), {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          this.last_evaluated_key =
            response.data.response_info.last_evaluated_key;
          let tmpGotFiles = response.data.response_info.path_list;
          this.gotFiles = tmpGotFiles.map((obj) => {
            let gotFiles = { ...obj, ...obj.info };
            delete gotFiles["info"];
            return gotFiles;
          });

          this.updateFlag.type = "success";
          this.updateFlag.msg = "取得に成功しました";
        })
        .catch((err) => {
          let errMsg = err.response.data.response_info;

          this.updateFlag.msg = errMsg;
          this.updateFlag.type = "error";
        })
        .finally(() => {
          this.fileUpLoading = false;
          return;
        });
    },
    async deleteItem() {
      this.deleteLoding = true;
      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/file/" +
          this.deleteFilePath
      );

      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      await axios
        .delete(URI, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then(() => {
          this.deleteFlag.type = "success";
          this.deleteFlag.msg = "削除しました。";
        })
        .catch((err) => {
          let errMsg = err.response.data.response_info;

          this.deleteFlag.msg = errMsg;
          this.deleteFlag.type = "error";
        })
        .finally(() => {
          this.deleteLoding = false;
          this.getFile();
        });
    },
    initDeleteFlag() {
      this.deleteFlag = {
        type: null,
        msg: null,
      };
    },
  },
};
</script>
