<template>
  <v-card flat class="pa-5">
    <v-card outlined class="pa-5">
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >マップ表示</v-card-title
      >
      <v-card-text class="text-left"
        >マップ表示を行うCSVを選択してください。
        <p>
          ・CSVには<strong class="red--text text--lighten-1"
            >「物件名」「所在地」</strong
          >という列名(カラム名)が必要です。<br />・表示できる最大物件数は<strong
            class="red--text text--lighten-1"
            >20</strong
          >です。
        </p></v-card-text
      >

      <v-file-input
        v-model="inputFile"
        background-color="white"
        outlined
        accept=".csv"
        chips
        label="ファイル"
        required
      ></v-file-input>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          x-large
          :dark="!inputFile == false"
          :disabled="!inputFile"
          rounded
          class="cyan darken-3 pl-10 pr-10"
          :loading="fileUpLoading"
          @click="submitSendFile()"
          >作成</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-alert
        class="mt-5"
        v-show="!updateFlag.type === false"
        dense
        outlined
        :type="updateFlag.type"
        >{{ updateFlag.msg }}
      </v-alert>
      <v-card-text v-if="!url == false"
        >リンクの有効時間は<strong class="red--text text--lighten-1"
          >1時間</strong
        >です。</v-card-text
      >

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          x-large
          rounded
          target="_blank"
          :dark="!url == false"
          :disabled="!url"
          class="cyan darken-3 pl-10 pr-10"
          :href="url"
          >表示</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Auth from "@aws-amplify/auth";
import handleMap from "../plugins/handleMap";

Vue.use(handleMap);

export default {
  name: "inputHtmlFile",
  components: {},
  data: () => ({
    inputFile: null,
    fileUpLoading: false,
    mapSaving: false,
    memo: null,
    updateFlag: {
      type: null,
      msg: null,
    },
    url: null,
  }),
  computed: {
    coordsInfoFlag() {
      return this.coordinates.length > 0;
    },
  },
  mounted: function () {
    this.loadHtml();
  },
  methods: {
    loadHtml() {
      fetch("./rflow/src/assets/map1.html").then((res) => {
        res.text().then((html) => {
          this.htmlContent = html;
        });
      });
    },
    getUrlFromFile(file) {
      return new Promise((resolve) => {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          resolve(fr.result);
        });
      });
    },
    async submitSendFile() {
      // init
      this.url = null;
      this.updateFlag = {
        type: null,
        msg: null,
      };

      this.fileUpLoading = true;
      const selectedFiles = {
        file: await this.getUrlFromFile(this.inputFile),
      };

      // post
      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/map"
      );

      // get token from cognito
      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      await axios
        .post(URI, JSON.stringify(selectedFiles), {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          const data = response.data;
          this.url = data.url;
          const removed = data.removed;
          if (removed.length > 0) {
            this.updateFlag.msg =
              "一部の物件に緯度経度が見つかりませんでした。以下の住所がマップから削除されています。" +
              removed +
              "。住所を修正するか、時間をおいて再作成してください。";
            this.updateFlag.type = "warning";
          }
        })
        .catch((err) => {
          let errMsg = err.response.data.msg
          if (errMsg.includes("overLimitPropertyNumber")) {
            this.updateFlag.msg = "物件数が20以上です。";
          } else if (errMsg.includes("noAddressColumn")) {
            this.updateFlag.msg = "「所在地」カラムがありません。";
          } else if (errMsg.includes("noNameColumn")) {
            this.updateFlag.msg = "「物件名」カラムがありません。";
          } else if (errMsg.includes("overMaxMapNum")) {
            this.updateFlag.msg =
              "マップ作製数が上限に達しています。営業にご確認ください。";
          } else {
            this.updateFlag.msg = "作成に失敗しました。" + errMsg;
          }

          this.updateFlag.type = "error";
          this.fileUpLoading = false;
        })
        .finally(() => {
          this.fileUpLoading = false;
        });
    },
  },
};
</script>
