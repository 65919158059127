import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router.js";
import store from "./store";
import Auth from "@aws-amplify/auth";
import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;

const Amplify = require("aws-amplify");

Amplify.default.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-northeast-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-northeast-1_593RU6hva",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "320osssp5rae4unh59jli914mo",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
  },
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth != false)) {
    const user = await Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          return data;
        }
      })
      .catch(() => {
        return next({
          path: "/login",
        });
      });

    if (!user) {
      return next({
        path: "/login",
      });
    } else {
      return next();
    }
  } else {
    next();
  }
});

new Vue({
  vuetify,
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
