<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          readonly
          v-bind="attrs"
          :label="label"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        color="cyan darken-3"
        locale="ja"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 120000)
            .toISOString()
            .substr(0, 10)
        "
        min="2010-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ['label'],
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.$emit("selected-date", date);
    },
  },
};
</script>