<template>
  <v-dialog max-width="500px" v-model="flag">
    <template v-slot:activator="{ on, attrs }">
      <p text v-bind="attrs" v-on="on">{{ date }} {{ title }}</p>
    </template>
    <v-card flat>
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >お知らせ</v-card-title
      >
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ msg }}</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
    },
    title: {
      type: String,
    },
    msg: {
      type: String,
    },
  },
  data() {
    return {
      flag: false,
    };
  },
};
</script>
