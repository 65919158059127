<template>
  <v-container :min-height="$vuetify.breakpoint.height">
    <v-row class="text-center">
      <v-spacer cols="0" sm="0" md="2" lg="2" xl="2"></v-spacer>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10">
        <v-img
          :src="require('../assets/starlingDB-logo.png')"
          contain
          height="250"
          :max-width="$vuetify.breakpoint.width"
        />
      </v-col>
      <v-spacer cols="0" sm="0" md="2" lg="2" xl="2"></v-spacer>
    </v-row>

    <v-row class="text-center">
      <v-col>
        <v-card-text class="text-center text-h4 grey--text text--darken-1"
          >R-Buckets</v-card-text
        >
      </v-col>
    </v-row>

    <v-row>
      <v-spacer cols="0" sm="0" md="2" lg="2" xl="2"></v-spacer>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10">
        <v-card outlined :loading="msgLoading">
          <template slot="progress">
            <v-progress-linear
              color="cyan darken-3"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-sheet
            id="scrolling-techniques-7"
            class="overflow-y-auto"
            max-height="80"
          >
            <v-list dense>
              <v-list-item-group color="cyan darken-3">
                <v-list-item v-for="(msg, i) in messages" :key="i">
                  <v-list-item-content>
                    <v-list-item-action-text>
                      <message
                        :date="msg.target_date"
                        :title="msg.title"
                        :msg="msg.msg"
                      ></message>
                    </v-list-item-action-text>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-sheet>
        </v-card>
      </v-col>

      <v-spacer cols="0" sm="0" md="2" lg="2" xl="2"></v-spacer>
    </v-row>

    <v-row class="text-center">
      <v-spacer cols="0" sm="0" md="2" lg="2" xl="2"></v-spacer>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10">
        <v-card :max-width="$vuetify.breakpoint.width" outlined>
          <v-tabs
            v-model="tab"
            class="pa-2 text-h2"
            color="cyan darken-3"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            centered
            fixed-tabs
          >
            <v-tab v-for="(title, n) in headers" :key="n">
              <h3>{{ title }}</h3>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <inputHtmlFile></inputHtmlFile>
            </v-tab-item>

            <v-tab-item>
              <getHistoryFile></getHistoryFile>
            </v-tab-item>

            <v-tab-item>
              <showMap></showMap>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-spacer cols="0" sm="0" md="2" lg="2" xl="2"></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Auth from "@aws-amplify/auth";
import inputHtmlFile from "../components/inputHtmlFile";
import getHistoryFile from "../components/getHistoryFile";
import message from "../components/message";
import showMap from "../components/showMap";

export default {
  name: "home",
  data: () => ({
    tab: null,
    headers: ["excelの取得", "過去分の取得", "マップ表示"],
    messages: ["お知らせなし"],
    msgLoading: false,
  }),
  components: {
    inputHtmlFile,
    getHistoryFile,
    message,
    showMap,
  },
  created() {
    this.getMsg();
    this.getUserList();
  },
  methods: {
    async getMsg() {
      this.msgLoading = true;
      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/msg"
      );

      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      await axios
        .get(URI, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          const message = JSON.parse(response.data.response_info);
          if (message.length == 0) {
            this.messages = [
              {
                target_date: null,
                title: "お知らせなし",
                msg: null,
              },
            ];
          } else {
            this.messages = message;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.msgLoading = false;
          return;
        });
    },
    async getUserList() {
      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/user"
      );

      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      await axios
        .get(URI, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          const userList = JSON.parse(response.data.response_info);
          this.$store.dispatch("setUserList", userList.user_list);
          this.$store.dispatch(
            "setFilterType",
            Object.keys(userList.filter_type)
          );
          this.$store.dispatch("setFilterDetail", userList.filter_type);
        })
        .catch(() => {})
        .finally(() => {
          return;
        });
    },
  },
};
</script>
