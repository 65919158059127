import axios from "axios";

const handleMap = {
  install(Vue) {
    let mapLoaded = false;
    const loadScript = () => {
      let script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.VUE_APP_MAPAPIKEY +
        "&callback=initMap";
      script.async = true;
      document.head.appendChild(script);
    };

    loadScript();

    window.initMap = () => {
      mapLoaded = true;
    };

    const showMap = (canvas, myLatLng) => {
      let marker = [];
      let infoWindow = [];

      const map = new window.google.maps.Map(canvas, {
        center: myLatLng[0],
        zoom: 15,
      });

      const markerEvent = (i) => {
        marker[i].addListener("click", function() {
          infoWindow[i].open(map, marker[i]);
        });
      };

      for (let i = 0; i < myLatLng.length; i++) {
        marker[i] = new window.google.maps.Marker({
          position: myLatLng[i],
          map,
        });
        infoWindow[i] = new window.google.maps.InfoWindow({
          // content
          content: '<div class="sample">' + "test" + i + "</div>",
        });
        markerEvent(i);
      }
    };

    Vue.prototype.$showMap = (canvas, myLatLng) => {
      if (mapLoaded) {
        showMap(canvas, myLatLng);
      } else {
        let timer = setInterval(() => {
          if (mapLoaded) {
            clearInterval(timer);
            showMap(canvas, myLatLng);
          }
        }, 500);
      }
    };

    const searchLatLon = async function(address) {
      const addressQuery = encodeURI(
        "https://msearch.gsi.go.jp/address-search/AddressSearch?q=" + address
      );

      const geo = await axios
        .get(addressQuery)
        .then((response) => {
          return response.data[0].geometry.coordinates;
        })
        .catch(function(e) {
          console.log(e);
        });

      return geo;
    };

    Vue.prototype.$searchLatLon = function(address) {
      const geo = searchLatLon(address);
      return geo;
    };
  },
};
export default handleMap;
