<template>
  <v-dialog max-width="600px" v-model="flag">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="white" text rounded class="my-2">
        プライバシーポリシー
      </v-btn>
    </template>
    <v-card flat>
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >プライバシーポリシー</v-card-title
      >
      <v-card-text
        >このプライバシーポリシーは株式会社Staling（以下総称して「当社」といいます。）が当社の運営するウェブサイト群（以下「本ウェブサイト」といいます。）及び当社の運営するスマートフォンアプリ群（以下「本アプリ」といいます。）を利用するお客様から収集した個人情報（個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に定義される「個人情報」をいいます。）を保護する為の方針を定めています。
        なお本ウェブサイトは「www.r-buckets.com」を指すものとし、「www」以外の複数のサブドメインについてもこれに含まれるものとします。
      </v-card-text>
      <v-card-subtitle>1 法令などの遵守について</v-card-subtitle>
      <v-card-text>
        当社は、個人情報保護の実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を遵守いたします。
      </v-card-text>
      <v-card-subtitle>2 個人情報の収集方法とその目的について</v-card-subtitle>
      <v-card-text>
        当社は本ウェブサイト及び本アプリ内で、お客様の個人情報を収集する場合があります。
        当社は、個人情報の取り扱いについて業務実態に応じた個人情報保護のための管理体制を確立するとともに、当該情報を以下の目的の範囲内で社内規程等に従い適切かつ慎重に使用します。
        <ul>
          <li>
            当社の提供するサービス（以下「本サービス」といいます。）の提供及び管理のため
          </li>
          <li>お客様の本サービスの利用状況、稼働状況を案内するため</li>
          <li>本サービスの案内のため</li>
          <li>本サービスの改善及び開発のため</li>
          <li>問い合わせ対応のため</li>
          <li>アンケートの実施のため</li>
          <li>お客様に合わせてカスタマイズしたコンテンツを提供するため</li>
          <li>マーケティング及びキャンペーンでの利用のため</li>
          <li>
            個人又は特定の利用者を識別できない形式に加工した、本サービスの利用状況に関する統計データを作成するため
          </li>
          <li>その他前各項目に付帯・関連する事項のため</li>
        </ul>

        なお、本ウェブサイト及び本アプリから取得した個人情報を当社が取得・保有する他の情報で補足する場合があります。本ウェブサイト及び本アプリで収集した個人情報を収集当初の目的の範囲を超えて利用することはありません。
        当社が個人情報を第三者から間接的に取得する場合は、当該第三者が本人から適正に取得したものであるかどうかを確認した上で、上記目的の範囲内で利用します。
      </v-card-text>
      <v-card-subtitle>3 個人情報の第三者への提供について</v-card-subtitle>
      <v-card-text>
        当社は、お預かりした個人情報をお客様からの事前の同意を得ることなく第三者に提供することは行いません。ただし、以下の場合は除きます。
        <ul>
          <li>
            当初の利用目的の範囲内で外部の委託先への個人情報の提供が必要になった場合（※なお、委託先への個人情報の提供が必要になった際には、委託先に対し情報管理に関する適切な監督を行ったうえで提供を行います。）
          </li>
          <li>当社内での以下の共同利用を行う場合</li>
          <li>共同利用される情報項目：当社が取得した個人情報</li>
          <li>共同利用の目的：上記２記載の目的と同じです</li>
          <li>共同利用の範囲：株式会社Starlingです</li>
          <li>共同利用における管理責任者：株式会社Starlingです</li>
          <li>その他個人情報保護法に基づき許容される場合</li>
        </ul>
      </v-card-text>
      <v-card-subtitle
        >4
        ログファイル・クッキー・ウェブビーコン等の技術的情報について</v-card-subtitle
      >
      <v-card-text>
        本ウェブサイト及び本アプリでは、ログファイルの収集、クッキーの送信（広告の効果測定を目的とした第三者サーバーからのクッキー送信を含む）、ウェブビーコンの設置などによりお客様のIPアドレス、アクセス回数、ご利用ブラウザ、OSその他利用端末などの情報を収集しています。本アプリをご利用の場合、アプリからご利用の端末のID、wi-fi接続に関する情報も取得致します。クッキーはお客様がご利用のブラウザの設定によって受け入れを拒否することができますが、その場合にはウェブサイトのサービスの一部がご利用頂けなくなる可能性があります。これらの情報は、本ウェブサイト及び本アプリの利用傾向の分析や問題発生時の原因究明を目的に収集しており、個人を特定する目的の利用はしておりません。
      </v-card-text>
      <v-card-subtitle
        >5
        個人情報に関する本人からの開示、訂正、削除、消去、及び利用停止等の方法について</v-card-subtitle
      >
      <v-card-text>
        お客様は、個人情報保護法に従い、お客様ご本人の個人情報の開示、訂正、削除、消去、及び利用停止等を請求することができます。個人情報の開示、訂正、削除、消去、及び利用停止等のご請求、並びにご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、以下のメールアドレス宛にご連絡ください。ご本人であることを確認したうえで対応致します。
        <p></p>
        <p>contact@starling-db.co.jp</p>
      </v-card-text>
      <v-card-subtitle>6 開示に対する法的免責事項について</v-card-subtitle>
      <v-card-text>
        法律の定めにより、国、地方自治体、裁判所、警察その他法律や条例などで認められた権限を持つ機関より要請があった場合には、これに応じてお客様の同意なく情報を開示することがあります。
      </v-card-text>
      <v-card-subtitle>7 従業員及び委託先の監督について</v-card-subtitle>
      <v-card-text>
        当社では、セキュリティや個人情報保護に関する社内教育等を定期的に実施し、従業員の個人情報保護への意識の向上、啓発に努めています。また、当社は、各種業務において、個人情報の取扱いの全部又は一部を委託する場合があります。この場合、当社は、個人情報を適正に取り扱うと認められるものを選定し、委託契約において、安全管理措置、秘密保持、再委託の条件その他の個人情報の取扱いに関する事項について適正に定め、必要かつ適切な監督を実施します。
      </v-card-text>
      <v-card-subtitle>8 セキュリティ対策について</v-card-subtitle>
      <v-card-text>
        当社は、収集した個人情報保護のために合理的な予防措置を講じ、オンライン・オフラインの両方において保護を行います。SSLなどの技術を用いて個人情報の取り扱いには細心の注意を図ると共に、業務上必要とされる権限を与えられた者のみがアクセス可能としています。
      </v-card-text>
      <v-card-subtitle
        >9 収集当初の目的と異なった利用に該当する場合について</v-card-subtitle
      >
      <v-card-text>
        当社では、原則として、収集時点のプライバシーポリシーに記載されている利用目的の範囲を超えてお客様の個人情報を利用することはありません。当初の利用目的の範囲を超えて個人情報を利用することになった際には、事前にお客様の同意を得たうえで行います。
      </v-card-text>
      <v-card-subtitle>10 リンク先の個人情報の取扱いについて</v-card-subtitle>
      <v-card-text>
        本ウェブサイト及び本アプリには外部のリンクが複数存在します。本プライバシーポリシーの適用範囲は本ウェブサイト及び本アプリのみとなりますので、外部のウェブサイト等での個人情報の取扱についてはリンク先ウェブサイトのプライバシーポリシーをご参照ください。ただし、アンケートについては、外部のASPを使用しており本ウェブサイト以外のドメインに遷移することがあります。そちらでの個人情報の取扱に関しては本プライバシーポリシーの適用範囲になります。
      </v-card-text>
      <v-card-subtitle
        >11
        プライバシーポリシーの変更・更新通知及び苦情について</v-card-subtitle
      >
      <v-card-text>
        当社は、本サービスを定期的に見直し、改善を行います。それに伴い、プライバシーポリシーを変更する場合があります。プライバシーポリシーに重要な変更がある場合には、本ウェブサイト上で告知します。また当社の個人情報の取扱に関してのお問合わせ・苦情等は以下の連絡先までご連絡ください。
        <p></p>
        <p>
          株式会社Starling<br /> 東京都中野区東中野五丁目１１番８号<br />contact@starling-db.co.jp
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      flag: false,
    };
  },
};
</script>
