<template>
  <v-dialog v-model="dialog" max-width="750px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn target="_blank" text v-bind="attrs" v-on="on">
        <span class="mr-1" v-if="$vuetify.breakpoint.width > 650"
          >ユーザー管理</span
        >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-card flat class="pa-5">
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >ユーザー情報</v-card-title
      >
      <v-card-subtitle class="mt-1"
        >{{ this.$store.state.companyName }}のユーザー一覧</v-card-subtitle
      >

      <v-card outlined class="mt-5 mr-5 ml-5">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <deleteUser :user_list="user_list"></deleteUser>
          <addUser></addUser>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              select
              :headers="user_header"
              :items="user_list"
              :items-per-page="10"
            >
            </v-data-table
          ></v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from "axios";
import Auth from "@aws-amplify/auth";
import addUser from "./user/addUser.vue";
import deleteUser from "./user/deleteUser.vue";

export default {
  name: "userList",
  data: () => ({
    dialog: false,
    user_header: [
      { text: "アカウント名", value: "person_in_charge" },
      { text: "ユーザー名", value: "display_name" },
      { text: "部署", value: "division" },
      { text: "email", value: "email" },
    ],
    user_list: [],
  }),
  components: {
    addUser,
    deleteUser,
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    async getUserList() {
      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/user"
      );

      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      await axios
        .get(URI, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          const userList = JSON.parse(response.data.response_info);
          this.user_list = userList.user_list;
        })
        .catch(() => {})
        .finally(() => {
          return;
        });
    },
  },
};
</script>
