import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    username: null,
    companyName: null,
    signedIn: null,
    userList: [],
    filterType: [],
    filterDetail: {}
  };
};

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {},
  mutations: {
    resetAllData(state) {
      Object.assign(state, getDefaultState());
    },
    setUser(state, username) {
      state.username = username;
    },
    setCompanyName(state, companyName) {
      state.companyName = companyName;
    },
    setSignedIn(state, signedIn) {
      state.signedIn = signedIn;
    },
    setUserList(state, user) {
      state.userList = user;
    },
    setFilterType(state, filter) {
      state.filterType = filter;
    },
    setFilterDetail(state, filter) {
      state.filterDetail = filter;
    }
  },
  actions: {
    resetAllData({ commit }) {
      commit("resetAllData");
    },
    setUser({ commit }, username) {
      commit("setUser", username);
    },
    setCompanyName({ commit }, companyName) {
      commit("setCompanyName", companyName);
    },
    setSignedIn({ commit }, signedIn) {
      commit("setSignedIn", signedIn);
    },
    setUserList({ commit }, user) {
      commit("setUserList", user);
    },
    setFilterType({ commit }, filter) {
      commit("setFilterType", filter);
    },
    setFilterDetail({ commit }, filter) {
      commit("setFilterDetail", filter);
    },
  },
  modules: {},
});
