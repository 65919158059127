<template>
  <v-dialog v-model="dialog" max-width="750px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mt-1 ml-1 mr-1" icon v-bind="attrs" v-on="on"
        ><v-icon dark> mdi-plus </v-icon></v-btn
      >
    </template>
    <v-card flat class="pa-5">
      <v-card-title
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >ユーザーの追加</v-card-title
      >
      <v-card-subtitle class="mt-1"
        >新規にユーザーを追加します。追加されたユーザーはパスワードの変更が必要です。<a
          href="mailto:contact@starling-db.co.jp"
          >csvでの一括登録は営業へお問い合わせください。</a
        ></v-card-subtitle
      >

      <v-card flat class="mt-5 mr-5 ml-5">
        <v-row>
          <v-col>
            <v-text-field
              v-model="person_in_charge"
              label="アカウント名"
              outlined
              hint="ログインの際に使用するアカウント名です。"
              background-color="white"
              required
              :rules="[rules.account]"
            ></v-text-field>
            <v-text-field
              v-model="display_name"
              label="ユーザー名"
              outlined
              background-color="white"
              required
            ></v-text-field>
            <v-text-field
              v-model="division"
              label="所属部署"
              outlined
              background-color="white"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="メールアドレス"
              outlined
              background-color="white"
              required
              :rules="[rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-alert
          v-show="updateFlag.type != null"
          dense
          outlined
          :type="updateFlag.type"
          >{{ updateFlag.msg }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            x-large
            :dark="!inputFlag"
            :disabled="inputFlag"
            rounded
            class="cyan darken-3 pl-10 pr-10"
            :loading="userLoading"
            @click="submitNewUser()"
            >追加</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from "axios";
import Auth from "@aws-amplify/auth";

export default {
  name: "addUsers",
  data: () => ({
    dialog: false,
    person_in_charge: null,
    display_name: null,
    division: null,
    email: null,
    userLoading: false,
    updateFlag: {
      type: null,
      msg: null,
    },
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Emailの形式が不適切です。";
      },
      account: (value) => {
        const pattern = /^([a-z0-9!@#$%^&*()_+-={}]{4,})$/;
        return (
          pattern.test(value) ||
          "アカウント名の形式が不適切です。英（小文字のみ）数字記号（!@#$%^&*()_+-={}）4文字以上で設定してください。"
        );
      },
    },
  }),
  computed: {
    inputFlag() {
      return (
        !this.person_in_charge ||
        !this.display_name ||
        !this.division ||
        !this.email ||
        !this.checkAccount ||
        !this.checkEmail
      );
    },
    checkAccount() {
      const pattern = /^([a-z0-9!@#$%^&*()_+-={}]{4,})$/;
      return pattern.test(this.person_in_charge);
    },
    checkEmail() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(this.email);
    },
  },
  methods: {
    async submitNewUser() {
      if (
        !this.person_in_charge ||
        !this.display_name ||
        !this.division ||
        !this.email ||
        !this.checkAccount ||
        !this.checkEmail
      ) {
        return;
      }

      this.userLoading = true;

      const URI = encodeURI(
        "https://mo87shdglf.execute-api.ap-northeast-1.amazonaws.com/dev/user"
      );

      const idToken = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data.signInUserSession.idToken.jwtToken;
          }
        })
        .catch(() => {
          this.updateFlag.type = "error";
          this.updateFlag.msg = "認証エラー";
        });

      const body = {
        person_in_charge: this.person_in_charge,
        display_name: this.display_name,
        division: this.division,
        email: this.email,
      };

      await axios
        .post(URI, body, {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        })
        .then((response) => {
          const userList = JSON.parse(response.data.response_info);
          this.user_list = userList.user_list;
          this.updateFlag.type = "success";
          this.updateFlag.msg =
            "設定されたメールアドレスにユーザー名と仮パスワードが送信されました。";
        })
        .catch((err) => {
          const errMsg = err.response.data.response_info;
          console.log(errMsg);

          if (errMsg.includes("notAuthorizedUser")) {
            this.updateFlag.type = "error";
            this.updateFlag.msg = "ユーザー追加の権限がありません。";
          } else if (errMsg.includes("UsernameExistsException")) {
            this.updateFlag.type = "error";
            this.updateFlag.msg =
              "既に存在しているアカウント名です。異なるアカウント名を作成してください。";
          } else if (errMsg.includes("Invalid email address")) {
            this.updateFlag.type = "error";
            this.updateFlag.msg = "メールアドレスの形式が不正です。";
          } else if (errMsg.includes("exceedMaxUserLimit")) {
            this.updateFlag.type = "error";
            this.updateFlag.msg = "ユーザー数が上限に達しています。営業にご確認ください。";
          } else {
            this.updateFlag.type = "error";
            this.updateFlag.msg = "エラー";
          }
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
  },
};
</script>
