<template>
  <v-app>
    <v-app-bar app color="cyan darken-3" dark>
      <div class="d-flex align-center">
        <v-app-bar-title
          class="text-subtitle-1"
          v-if="$vuetify.breakpoint.width > 650"
        >
          {{ this.$store.state.companyName }}
          {{ this.$store.state.username }}

        </v-app-bar-title>
      </div>
      <v-spacer></v-spacer>
      <div v-if="signInFlag">
        <userList></userList>
        <v-btn target="_blank" text @click="signOut()">
          <span class="mr-1" v-if="$vuetify.breakpoint.width > 650"
            >LOGOUT</span
          >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main class="mb-10">
      <router-view />
    </v-main>

    <v-footer class="mt-10" color="black" padless>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          :href="link.link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link.title }}
        </v-btn>
        <termsOfService></termsOfService>
        <privacyPolicy></privacyPolicy>
        <v-col class="cyan darken-3 py-4 text-center white--text" cols="12">
          Copyright © {{ new Date().getFullYear() }} 株式会社Starling
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Auth from "@aws-amplify/auth";
import userList from "./components/userList";
import privacyPolicy from "./components/link/privacyPolicy";
import termsOfService from "./components/link/termsOfService";

export default {
  name: "App",
  components: {
    userList,
    privacyPolicy,
    termsOfService,
  },
  data: () => ({
    dialog: false,
    links: [
      { title: "お問い合わせ", link: "mailto:contact@starling-db.co.jp" },
      { title: "運営会社", link: "https://starling-db.wixsite.com/starling" },
    ],
  }),
  computed: {
    signInFlag() {
      return this.$store.state.signedIn === true;
    },
  },
  mounted() {
    this.setCurrentUserInfo();
  },
  methods: {
    async setCurrentUserInfo() {
      const userdata = await Auth.currentAuthenticatedUser()
        .then((data) => {
          if (data && data.signInUserSession) {
            return data;
          }
        })
        .catch(() => {
          this.getDetailErrorMsg = "認証エラー";
        });

      // set current user info
      let companyName =
        userdata.signInUserSession.idToken.payload["cognito:groups"][0];
      this.$store.dispatch("setSignedIn", !!userdata);
      this.$store.dispatch("setUser", userdata.username);
      this.$store.dispatch(
        "setCompanyName",
        companyName.slice(0, companyName.length - 10)
      );
    },
    signOut() {
      Auth.signOut()
        .then(() => {
          // 初期化
          this.$store.dispatch("resetAllData");
          this.$router.push("/login");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
