<template>
  <v-dialog max-width="600px" v-model="flag">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="white" text rounded class="my-2">
        利用規約
      </v-btn>
    </template>
    <v-card flat>
      <v-card-title class="mb-5"
        ><v-divider vertical class="cyan darken-3 pa-1 ma-1"></v-divider
        >R-buckets利用規約
        <span class="ml-5 text-subtitle-2"> 最終更新日：2022年3月3日</span>
      </v-card-title>
      <v-card-subtitle>第１条（規約）</v-card-subtitle>
      <v-card-text
        >本規約は、株式会社Starling（以下「当社」といいます。）が提供するサービス（以下「本サービス」といいます。）の利用に関し、当社と会員の間に適用されます。</v-card-text
      >
      <v-card-subtitle>第２条（定義）</v-card-subtitle>
      <v-card-text>
        本規約において、次の各号に掲げる用語の意味は、当該各号に定めるとおりとします。
        <ol>
          <li>
            会員<br />
            本規約に同意の上、当社と本サービスの利用に関する契約（以下「本利用契約」といいます。）を締結した法人、団体、組合または個人をいいます。
          </li>
          <li>
            運用管理者<br />
            本サービスの申込み時に会員が指定した、本サービスの利用に関する管理者権限を有し、ユーザーの指定を行う会員の役員、従業員その他の関係者（会員が個人である場合は、当該個人）をいいます。
          </li>
          <li>
            ユーザー
            <br />運用管理者により指定され、本サービスへのアクセスおよび使用が許可されている者をいいます。運用管理者は、当社が別途定める人数の範囲でユーザーを指定することができます。
          </li>
          <li>
            利用者 <br />
            運用管理者及びユーザーをいいます。
          </li>
          <li>
            本サイト<br />
            当社が本サービスを提供するために運営するウェブサイトをいいます。本サービスは、本サイトまたは次号に定義する本ソフトウェアを介して会員および利用者に提供されます。
          </li>
          <li>
            本ソフトウェア<br />
            当社が本サービスを提供するために作成・公開するアプリケーション・ソフトウェアをいい、アップデート版、修正版、代替品および複製物を含みます。
          </li>
          <li>
            登録情報<br />
            本サービスを利用する前提として登録することが求められる、当社が定める会員に関する情報をいいます。
          </li>
          <li>
            会員情報<br />
            本サービスのために当社が管理するサーバーに保存された各種情報や通信記録その他の会員および利用者の一切の情報をいい、登録情報、本サービスを通じて当社が提供しまたは会員が取得した情報を含みます。
          </li>
        </ol>
      </v-card-text>

      <v-card-subtitle>第３条 （規約の変更）</v-card-subtitle>
      <v-card-text>
        <p>
          当社は、当社が必要と判断したときは、本規約を変更することができるものとします。
        </p>
        <p>
          ２
          当社が本規約の内容を変更するときは、当該変更の事実、その効力発生日および変更内容を本規約の定めに従い会員に通知または公表します。
        </p></v-card-text
      >
      <v-card-subtitle>第４条 （通知・公表）</v-card-subtitle>
      <v-card-text>
        <p>
          当社は、本サービスに関連して会員に通知または公表をする場合には、本サイトに掲示する方法または登録情報として登録された電子メールアドレス・住所に宛てて電子メール・文書を送信する方法など、当社が適当と判断する方法で実施します。
        </p>
        <p>
          ２
          本サービスに関する問い合わせその他会員から当社に対する連絡または通知は、当社の定める方法で行うものとします。
        </p></v-card-text
      >
      <v-card-subtitle>第５条 （本利用契約の成立（入会））</v-card-subtitle>
      <v-card-text>
        <p>
          本利用契約は、本サービスの利用を希望する者が、登録情報を登録した上で、当社が指定する方法で申込みを行い、これを当社が受諾した時点で成立するものとします。本サービスの利用を希望する者が当社が指定する方法で申込みを行った時点で、当該申込者は本規約に同意したものとみなされます。
        </p>
        <p>
          ２
          会員は、当社が前項の申込みを受諾した日（以下「契約成立日」といいます。）から本サービスを利用できます。
        </p>
        <p>
          ３
          未成年者が本サービスの利用を希望する場合には、法定代理人の同意が必要になります。未成年者から第1項の申込みがあったときは、本サービスの利用および本規約の内容について、法定代理人の同意があったものとみなします。
        </p>
      </v-card-text>
      <v-card-subtitle>第６条 （利用料金および支払方法）</v-card-subtitle>
      <v-card-text>
        <p>
          会員は、当社に対して、本サービスの利用の対価として、当社がサービスごとに別途定める利用料金を支払います。
        </p>
        <p>
          ２
          会員は、利用料金を、当社所定の決済手段（クレジットカード等）により、当社指定の期日までに支払うものとし、当社は、いかなる場合にも受領した利用料金の返金には応じません。
        </p>
        <p>
          ３
          本サービスを利用するために必要となる通信費（本ソフトウェアのダウンロードおよび利用のために発生する通信費を含みます。）、および通信機器等は、会員の負担と責任により準備するものとします。但し、会員の使用する通信機器等において、本サイトおよび本ソフトウェアが正常に動作することを保証するものではありません。
        </p>
      </v-card-text>

      <v-card-subtitle>第７条 （会員ID等）</v-card-subtitle>
      <v-card-text>
        <p>
          会員は、本サービス申込みの際に指定した会員ID（アカウント名）および当該会員IDにかかるパスワード（以下併せて「会員ID等」といいます。）を自己の責任において厳重に管理するものとし、これらを用いてなされた一切の行為についてその責任を負います。
        </p>
        <p>
          ２
          会員は、自己の責任において、利用者ごとに指定されたユーザーID（アカウント名）および当該ユーザーIDにかかるパスワード（以下併せて「ユーザーID等」といいます。）を厳重に管理し、またユーザーをして厳重に管理させるものとし、これらを用いてなされた一切の行為についてその責任を負います。
        </p>
        <p>３ 会員は、利用者以外の者に本サービスを利用させてはいけません。</p>
        <p>
          ４
          会員は、利用者に本規約の内容を遵守させるものとします。利用者の本規約違反は、会員の本規約違反とみなし、会員および利用者は連帯して責任を負うものとします。
        </p>
      </v-card-text>

      <v-card-subtitle>第８条 （登録情報の変更）</v-card-subtitle>
      <v-card-text>
        会員は、登録情報に変更が生じた場合には、当社が指定する方法により速やかに届出を行います。当社は、登録情報の変更の届出がなされなかったことにより会員に生じた損害について一切の責任を負いません。
      </v-card-text>

      <v-card-subtitle>第９条 （サービス継続性保証）</v-card-subtitle>
      <v-card-text>
        <p>
          当社は、サービス利用者に対し、本サービスの稼働時間を99.5%以上維持することを保証します。
        </p>
        <p>
          ２
          サービス利用者の１ヶ月間（当月の１日から末日までをいうものとし、直前30日をいうものではありません。以下本条において同じです。）の使用不能時間が、3.6時間（１ヶ月の時間の0.5％に相当する時間です。）を超えた場合、サービス利用者は自らの使用不能時間を
          当社に対し申請できるものとし、当該申請された使用不能時間が適正であると当社が認めた場合（申請された使用不能時間の一部が適正であると認められ、認められた１ヶ月間の使用不能時間が3.6時間を超えた場合を含みます。）、当社は、以下に定める料率で、申請日の属する日の翌月分の利用料を減額するものとします。申請を認められたサービス利用者が既に申請日の翌月分の利用料金を支払っている場合、当社は、当該サービス利用者に対し、次の各号に定める場合に応じて算出した減額分相当額を当社の定める方法で返金するものとします。
        <ol>
          <li>１ヶ月間における使用不能時間が3.6時間を超え、7.2時間以下の場合
        月額利用料金（※）の20％</li>
            <li>１ヶ月間における使用不能時間が7.2時間を超えた場合
        月額利用料金（※）の40％
        ※サービス利用契約の契約期間が１年契約の場合、年額利用料金を月割計算の方法で算定した１ヶ月あたりの利用料金を月額利用料金として計算します。</li>
        </ol>
        </p>
        <p>３
        前項に規定する使用不能時間とは、本サービスの全利用者の５％を超える人数が、次の各号に該当する時間（以下、次の各号に該当する状態を「サービス停止状態」といいます。）をいいます。
        <ol>
          <li>本サイトを表示できない時間</li>
          <li>本サービスにログインできない時間</li>
          <li>本サービスによる情報を閲覧することができない時間 </li>
        </ol>
        </p>
        <p>
          ４
        前項にかかわらず、次の各号に該当する場合は、使用不能時間に当たらないものとします。
                <ol>
          <li>午前1時から午前6時（日本時間）までの間のサービス停止状態</li>
          <li>10分未満のサービス停止状態</li>
          <li>利用者の環境に依存するサービス停止状態 </li>
          <li>システムアップデートによるサービス停止状態のうち、当社が当該アップデート開始の５日以上前に本サイト又は本アプリにより告知した時間帯におけるサービス停止状態</li>
          <li>本利用規約に基づきサービスが中断・停止する場合</li>
        </ol>
        </p>
        <p>５
        サービス利用者が第2項の申請を行うことができるのは、サービス利用者の１ヶ月間における使用不能時間が3.6時間を超えた月の翌月末日までとし、それ以後に申請があったときは、当社は第１項に定める義務を負わないものとします
        </p>
      </v-card-text>
      
      <v-card-subtitle>第１０条 （第三者サービス）</v-card-subtitle>
      <v-card-text>
        <p>本サービスにおける当社以外の第三者がウェブサイトまたはアプリケーション・ソフトウェアを介して運営するサービス（以下「第三者サービス」といい、同期先サービスを含みます。）との連携は、当社と第三者サービスの運営者との間の提携、協調、授権その他の一切の協力関係を意味するものではなく、会員は、第三者サービスとの連携により取得されるデータ等の正確性、完全性等につき、適宜、連携先サイトにおいても確認を行うものとします。
      </p>
        <p>２
        会員は、自己の責任において、第三者サービスを利用するものとし、第三者サービスとの連携に起因する当該サイト・サービスの運営者または第三者との間での紛争その他一切の債権債務関係について、自己の責任と費用で解決するものとし、当社に何ら迷惑をかけず、またこれにより当社が被った損害（弁護士費用を含みます。）を補償します。
      </p>
        <p>３
        会員は、第三者サービスとの連携により取得するデータが、通信設備等の異変により本サイトにおいて正確に表示されない可能性があることを予め了承します。
    </p>
        <p>４
        第三者サービスの利用は、会員と第三者サービスの運営者との間での別途の契約に従うものとします。</p>
      </v-card-text>

      <v-card-subtitle>第１１条 （バックアップ）</v-card-subtitle>
      <v-card-text>
        <p>会員は、会員情報の全てについて、自己の責任において記録し、保存・管理します。 </p>
        <p>２
        会員は、会員情報について、自己の責任においてバックアップ作業（当社が提供する本サービスの機能を利用する場合を含みますが、それに限りません。）を行うものとし、当社は、バックアップデータが存在しないこと、または会員がバックアップ作業を適切に実施しなかったこと等により発生した会員の損害および不利益につき、一切の責任を負いません。
      </p>
        <p> ３
        当社は、会員情報をバックアップとして記録することがあります。但し、前項に定める会員の責任において行うバックアップを補完するものではなく、会員情報の復旧を保証するものではありません。
      </p>
      </v-card-text>

      <v-card-subtitle>第１２条 （禁止行為）</v-card-subtitle>
      <v-card-text>
        <p>会員は、本サービスに関連して次の各号に定める行為を行ってはいけません。
          <ol>
            <li>当社に対して虚偽の申告をする行為</li>
            <li>本利用契約に基づき当社から提供された本サイトおよび本ソフトウェアを含む情報および役務を本サービスの利用以外の目的のために使用する行為</li>
            <li>当社もしくは第三者の財産（知的財産権を含みます。）、プライバシー、名誉、信用、肖像、パブリシティーその他の権利・利益を侵害する行為または侵害するおそれのある行為</li>
            <li>前号以外で当社もしくは第三者の権利・利益を侵害する行為または侵害するおそれのある行為</li>
            <li>法令に違反し、もしくは公序良俗に反する行為またはそのおそれのある行為</li>
            <li>第三者の会員ID等、ユーザーID等または同期先ID等を不正に使用または取得する行為その他会員または利用者が、第三者を装って本サービスを利用する行為</li>
            <li>コンピュータウィルスなどの有害なプログラムを使用し、もしくは送信する行為、またはそのおそれのある行為</li>
            <li>前号に定めるものの他、不正アクセス行為等当社による業務の遂行、本サービスの実施もしくは当社の通信設備等に支障を及ぼし、またはそのおそれがあると当社が判断する行為</li>
          </ol>
        </p>
      </v-card-text>

      <v-card-subtitle>第１３条 （会員による本サービスの解約）</v-card-subtitle>
      <v-card-text>
        <p>会員は、本利用契約を解約する場合、当社所定の方法により解約手続きを行うこととし、当該解約手続きの完了をもって、本利用契約が解約されるものとします。この場合、会員は自己の責任において、当社からの解約に関する通知を確認するものとします。
         </p>
        <p>２
        前項に基づき会員が解約した場合でも、当社は、既に受領した利用料金の返金は行いません。      </p>
        <p> 
        ３
        会員が第１項により本利用契約を解約した場合、当社は会員情報を消去することができます。      </p>
      </v-card-text>


      <v-card-subtitle>第１４条 （当社による契約解除）</v-card-subtitle>
      <v-card-text>
        <p>当社は、会員が次の各号の一つに該当した場合には、会員に対して何らの通知催告をすることなく、本利用契約の一部または全部を解除して会員に対する退会処分を行い、または本サービスの提供を停止することができます。

          <ol>
            <li>本規約に違反する行為を行った場合</li>
            <li>当社に提供された登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合</li>
            <li>現に制限行為能力者であるか、または制限行為能力者になった場合において、催告後相当期間を経過しても法定代理人の記名押印のある同意書または追認書の提出がない場合</li>
            <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準ずる者を意味します。以下同じ。）である、資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っている、または反社会的勢力等が経営に実質的に関与している法人等であると当社が合理的に判断した場合</li>
            <li>クレジットカード会社、立替代行業者等により会員指定のクレジットカード、支払口座の利用が停止された場合</li>
            <li>仮差押、差押、競売、破産手続開始、会社更生手続開始、民事再生手続開始等の申立があった場合、または公租公課等の滞納処分を受けた場合</li>
            <li>過去に本サービスについて退会処分を受けたことが判明した場合</li>
            <li>会員および運用管理者が90日以上にわたって所在不明または連絡不能となった場合</li>
            <li>その他、当社が会員として不適当であると合理的に判断した場合</li>
          </ol>
        </p>
        <p>２
        前項に基づき会員が退会処分を受けた場合でも、当社は、既に受領した利用料金の返金は行わず、また、会員および第三者に生じた損害および不利益につき一切の責任を負いません。</p>
        <p>３
        第１項に基づき会員が退会処分を受けた場合、当社は会員情報を消去することができます。</p>
      </v-card-text>

      <v-card-subtitle>第１５条 （サービスの停止）</v-card-subtitle>
      <v-card-text>
        <p>当社は、次の各号のいずれかの事由が生じた場合には、事前に会員に通知をすることなく、本サービスの一部または全部を停止することができます。
          <ol>
            <li>本サービス提供にあたり必要なシステム、設備等に障害が発生し、またはメンテナンス、保守もしくは工事等が必要となった場合</li>
            <li>電気通信事業者が電気通信サービスの提供を中止するなど、当社以外の第三者の行為に起因して、本サービスの提供を行うことが困難になった場合</li>
            <li>非常事態（天災、戦争、テロ、暴動、騒乱、官の処分、労働争議等）の発生により、本サービスの提供が困難になった場合、または困難になる可能性のある場合</li>
            <li>同期可能サービスの事情により、同期可能サービスが利用できなくなった場合</li>
            <li>法令規制、行政命令等により、本サービスの提供が困難になった場合</li>
            <li>その他、当社の責めに帰することができない事由により、当社が必要やむを得ないと判断した場合</li>

          </ol>
        </p>
        <p>２
        当社は、前項に基づいて本サービスを停止したことにより会員および第三者に生じた損害および不利益につき一切の責任を負いません。</p>
      </v-card-text>

      <v-card-subtitle>第１６条 （サービスの変更）</v-card-subtitle>
      <v-card-text>
        <p>当社は、当社の裁量により本サービスの一部の内容を追加または変更することができます。当社は、本条に基づく本サービスの追加または変更により、変更前の本サービスのすべての機能・性能が維持されることを保証するものではありません。
        </p>
        <p>２
        当社は、前項に基づいて本サービスを追加または変更したことにより会員に生じた損害および不利益につき一切の責任を負いません。</p>
      </v-card-text>

      <v-card-subtitle>第１７条 （サービスの中止および終了）</v-card-subtitle>
      <v-card-text>
        <p>当社は、事前に会員に通知をしたうえで、当社の裁量により本サービスの一部もしくは全部の提供を中止または終了することができます。但し、中止または終了の内容が重大でない場合には、通知をすることなくこれらを実施することができます。
        </p>
        <p>２
        当社は、前項に基づいて本サービスを中止または終了したことにより会員に損害が発生した場合でも、一切の責任を負いません。</p>
      </v-card-text>

            <v-card-subtitle>第１８条（使用許諾）</v-card-subtitle>
      <v-card-text>
        <p>本ソフトウェアの提供 
        当社は、本サービスの利用に際して本ソフトウェアをダウンロードした利用者に対し、本規約に従うことを条件に、本ソフトウェアの非独占的な使用を許諾します。会員は、利用者をして、本ソフトウェアの使用に関し、本章に定める事項を遵守せしめるものとします。
        </p>
      </v-card-text>
      
      <v-card-subtitle>第１９条（ソフトウェアに関する禁止事項）</v-card-subtitle>
      <v-card-text>
        <p>会員は、本ソフトウェアの利用にあたり、第１２条に定める事項の他、次の各号に定める行為を行ってはいけません。
          <ol>
            <li>本ソフトウェアの複製、翻訳、翻案等の改変を行うこと</li>
            <li>本ソフトウェアの販売、配布、再使用許諾、公衆送信（送信可能化を含みます。）、貸与、譲渡、またはリースその他の処分を行うこと</li>
            <li>本ソフトウェアに設けられたコピーガード等の技術的な保護手段を回避する方法で使用すること</li>
            <li>本ソフトウェアの一部または全部のリバースエンジニアリング、逆コンパイルもしくは逆アセンブルを行い、またはその他の方法でソースコードを抽出すること</li>
            <li>第三者が複製できるように本ソフトウェアを公開すること</li>
            <li>前各号に定める他、本ソフトウェアの利用目的に照らして当社が不適切と判断する行為</li>

          </ol>
        </p>
        <p>２
        当社は、前項に基づいて本サービスを停止したことにより会員および第三者に生じた損害および不利益につき一切の責任を負いません。</p>
      </v-card-text>

      <v-card-subtitle>第２０条（利用制限）</v-card-subtitle>
      <v-card-text>
        <p>次の各号に定める場合、会員による本ソフトウェアの利用の一部または全部が制限されることがあります。
          <ol>
            <li>利用資格等の確認を目的としたライセンス認証、会員ID等の認証機能において、利用資格等の確認ができない場合。</li>
            <li>インターネット接続ができない場所において本ソフトウェアを利用する場合</li>
            <li>リアルタイム通信ができない通信状況において本ソフトウェアを利用する場合</li>
          </ol>
        </p>
        <p>２
        当社は、本ソフトウェアに関するサポート、修正版（アップデート版を含みます。）の提供を行う義務を負いません。またあらかじめ会員へ通知を行うことなく、本ソフトウェアの修正、変更、アップデート、または提供の終了を行う場合があります。
      </p>
      </v-card-text>

      <v-card-subtitle>第２１条 （保証）</v-card-subtitle>
      <v-card-text>
        <p>当社は、本サービスが推奨環境において機能するように合理的な最大限の努力を行います。但し、当社は、会員情報が正確性、正当性、有用性、完全性等を有することを保証するものではありません。会員は、会員情報について、自らの判断および責任において必要に応じ変更、修正等を行ったうえで利用するものとします。
        </p>
      </v-card-text>

      <v-card-subtitle>第２２条 （知的財産権）</v-card-subtitle>
      <v-card-text>
        <p>本サービスに関する著作権、著作者人格権、特許権、実用新案権、意匠権、商標権およびパブリシティ権等の一切の知的財産権は当社および正当な権利者たる第三者に帰属し、本利用契約の成立は、本サービスの利用に必要な範囲を超える知的財産権の利用許諾を意味するものではありません。
        </p>
      </v-card-text>

      <v-card-subtitle>第２３条 （損害賠償及び免責）</v-card-subtitle>
      <v-card-text>
        <p>当社は、本サービスに関して会員に生じた損害について、当社に故意または重過失が認められる場合には、当該損害の直接の原因となったサービスについて、当該会員から受領した利用料金の１か月分に相当する額を上限としてその損害を賠償するものとし、それ以外の損害については一切の責任を負いません。
        </p>
        <p>２
        会員は、本規約に違反することにより、または本サービスの利用に関して当社に損害を与えた場合、当社に対し当社に生じた損害を賠償しなければなりません。</p>
        <p>３
        本サービスに関して会員と第三者との間に紛争が生じた場合、会員は自己の責任と費用で解決するものとし、当社に何ら迷惑をかけず、またこれにより当社が被った損害（弁護士費用を含みます。）を補償します。
      </p>
      </v-card-text>

      <v-card-subtitle>第２４条 （委託）</v-card-subtitle>
      <v-card-text>
        <p>当社は、本サービスに関する業務の一部または全部を第三者に委託することができるものとします（以下その場合の当該第三者を「下請業者」といいます。）。
        </p>
      </v-card-text>

      <v-card-subtitle>第２５条 （情報管理）</v-card-subtitle>
      <v-card-text>
        <p>当社は、会員情報について、会員の事前の同意を得ずに第三者に開示しません。但し、次の各号の場合はこの限りではありません。
          <ol>
            <li>法令または公的機関からの要請を受け、要請に応じる必要を認めた場合</li>
            <li>人の生命、身体または財産の保護のために必要があり、かつ会員の同意を得ることが困難である場合</li>
            <li>会員に利用料金を請求する目的で、決済システム会社、クレジット会社、銀行等に本サービスを利用している登録ユーザーの情報を預託する場合</li>
            <li>本サービス提供または機能向上もしくは改善のために必要な受託者、または代理人等に情報を開示する場合</li>
            <li>当社および当社子会社間で連携したサービスを提供するために共同利用する場合</li>
          </ol>
        </p>
        <p>２
        前項にかかわらず、当社は、会員情報の属性集計・分析を行い、会員が識別・特定できないように加工したもの（以下「統計資料」といいます。）を作成し、本サービスおよび当社のその他のサービスのために利用することがあります。また、統計資料を第三者に開示することがあります。
        </p>
        <p>３
        当社は、会員情報の紛失、破壊、改鼠、漏洩等の危険に対して、合理的な最大限の安全対策を講じます。</p>
        <p>４
        当社は、電話応対品質向上等のため、会員との間の電話応対を録音し、録音内容を業務において使用することができるものとします。</p>
      </v-card-text>

      <v-card-subtitle>第２６条 （個人情報）</v-card-subtitle>
      <v-card-text>
        <p>当社の個人情報の取り扱いについてはプライバシーポリシーに定めるとおりとします。
        </p>
        <p>２
        当社は、本利用契約の終了後も、プライバシーポリシー記載の利用目的の範囲内で会員および利用者の個人情報を利用できるものとします。</p>
      </v-card-text>

      <v-card-subtitle>第２７条 （権利義務の譲渡禁止）</v-card-subtitle>
      <v-card-text>
        <p>会員は、当社の事前の書面による承諾を得ることなく、本利用契約に基づく権利義務を第三者に譲渡（合併、会社分割等による包括承継も含みます。）しまたは担保の目的に供してはならないものとします。
        </p>
      </v-card-text>

      <v-card-subtitle>第２８条 （準拠法、管轄裁判所）</v-card-subtitle>
      <v-card-text>
        <p>本規約および本利用契約は、日本法によって解釈され、日本法を準拠法とします。
        </p>
      </v-card-text>

      <v-card-subtitle>第２８条 （準拠法、管轄裁判所）</v-card-subtitle>
      <v-card-text>
        <p>本規約および本利用契約は、日本法によって解釈され、日本法を準拠法とします。
        </p>
        <p>２
        本利用契約に関して会員と当社の間に紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
      </v-card-text>

      <v-card-subtitle>第２９条 （本規約の適用制限）</v-card-subtitle>
      <v-card-text>
        <p>本規約および本利用契約は、日本法によって解釈され、日本法を準拠法とします。
        </p>
        <p>本規約の一部の条項が法令に違反するとされた場合、当該条項は、その限りにおいて本利用契約に適用されないものとし、本規約の他の条項は引き続き有効に存続するものとします。</p>
        <p>
        附則 令和4年3月3日 制定・施行</p>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      flag: false,
    };
  },
};
</script>
